import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 225.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">

<path d="M870 1845 c-94 -26 -181 -76 -251 -145 -222 -219 -222 -570 1 -789
86 -84 203 -141 327 -156 l51 -7 4 -97 c3 -88 6 -102 33 -147 34 -54 97 -98
163 -113 l42 -9 0 214 c0 246 -14 220 120 227 88 5 141 28 190 83 39 45 60 92
60 134 l0 32 -185 -4 -185 -3 0 95 0 95 280 -3 c318 -3 295 -10 263 83 -21 63
-85 127 -148 148 -41 14 -95 17 -344 17 l-296 0 3 -250 3 -250 -33 0 c-42 0
-124 38 -171 79 -20 18 -50 60 -67 93 -57 114 -37 246 51 342 66 73 130 101
229 100 60 0 87 -5 134 -27 100 -46 209 -33 283 35 l22 21 -26 34 c-38 50
-145 122 -222 151 -85 32 -247 40 -331 17z m280 -42 c76 -20 160 -67 205 -113
41 -42 40 -52 -8 -76 -48 -25 -122 -22 -200 10 -111 44 -226 36 -317 -22 -64
-41 -106 -88 -138 -152 -37 -75 -38 -198 -3 -277 20 -45 21 -56 10 -70 -11
-15 -11 -16 1 -5 19 17 40 15 40 -3 0 -8 7 -15 15 -15 18 0 20 -31 3 -49 -11
-10 -10 -11 3 -6 10 4 20 1 24 -4 3 -6 10 -9 15 -6 4 3 32 -6 61 -19 30 -14
70 -27 89 -30 30 -5 40 -2 73 29 36 34 47 67 27 80 -6 4 -10 -7 -10 -24 0 -17
-4 -31 -8 -31 -4 0 -8 19 -7 42 1 31 6 43 20 48 17 6 17 7 2 13 -15 6 -17 25
-17 156 0 82 3 156 6 165 9 24 536 24 592 0 44 -18 88 -58 109 -97 29 -56 26
-57 -236 -57 -132 0 -247 -3 -256 -6 -25 -10 -45 -59 -45 -114 0 -37 -4 -50
-14 -50 -8 0 -23 -11 -34 -25 -14 -17 -22 -21 -25 -13 -3 7 -6 -4 -6 -24 -1
-21 -6 -38 -12 -38 -6 0 -9 15 -7 37 2 26 -2 38 -12 40 -13 4 -13 3 0 -6 10
-7 11 -11 3 -11 -18 0 -16 -45 2 -60 16 -14 35 -6 35 16 0 8 5 14 10 14 6 0
10 8 10 19 0 23 25 45 44 38 8 -3 17 -1 20 6 2 7 5 -5 5 -26 1 -24 5 -36 14
-35 6 2 11 -2 10 -9 -2 -8 4 -10 14 -6 10 4 14 2 10 -4 -4 -6 3 -12 16 -15 35
-6 57 2 50 18 -4 10 0 12 11 8 9 -3 14 -10 11 -14 -3 -4 4 -10 16 -13 16 -4
18 -3 9 8 -8 9 -7 14 5 19 9 3 15 0 15 -9 0 -18 16 -20 26 -4 5 8 8 8 13 0 13
-20 66 -24 84 -7 23 24 37 19 37 -11 0 -40 -33 -88 -80 -119 -34 -23 -57 -29
-131 -34 -74 -5 -93 -10 -114 -30 -25 -23 -25 -26 -25 -184 0 -89 -3 -171 -6
-183 -9 -31 -42 -30 -87 5 -54 40 -78 97 -85 197 -6 102 -13 111 -111 131
-164 34 -290 123 -366 259 -113 202 -77 454 87 616 86 85 188 132 328 153 38
5 119 -4 180 -21z"/>
<path d="M712 1088 c-7 -7 -12 -17 -12 -22 0 -6 5 -4 11 4 9 12 10 11 6 -7 -3
-15 -1 -20 9 -16 16 6 19 33 3 33 -5 0 -7 5 -4 10 8 14 2 13 -13 -2z"/>
<path d="M1193 1083 c-7 -2 -13 -14 -13 -24 0 -13 -4 -18 -12 -13 -7 5 -8 3
-3 -6 6 -10 11 -10 20 3 7 9 15 14 18 10 4 -3 7 3 7 15 0 12 -1 22 -2 21 -2 0
-9 -3 -15 -6z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
